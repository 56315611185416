import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import TLCLogo from '../img/tlclogo.png';
import TLCFooterLogo from '../img/logo.png';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../css/App.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Alert from '@mui/material/Alert';
import scrollToElement from "scroll-to-element";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.primary,
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#f15a24',
      text: '#000000',
    },
    secondary: {
      main: '#69deea',
    },
  },
});

const baseUrl = "https://uat-api.truebytlc.com/v3/api/mlspin-md/";
const nonExpToken = "25AB97AE-7C6F-4F32-8EB0-B324487CCF22";

const initValues = {
  FirstName: "",
  LastName: "",
  Email: "",
  Phone: "",
  MLS: [],
  MLSNames: [],
  NewMLSName: "",
  TopMsg: "",
  Products: []
};

function App() {
  const [mlsList, setMLSList] = React.useState([{Id: 0, MLSName: "Other"}]);
  const [mlsNamesList, setMLSNamesList] = React.useState(["Other"]);
  const [submitStatus, setSubmitStatus] = React.useState(0);
  const [values, setValues] = React.useState({...initValues});

  const handleMLSChange = (event) => {
    // console.log("handleMLSChange");    
    const { target: { value } } = event;
    var nmlst = values.MLSNames;    
    nmlst = typeof value === 'string' ? value.split(',') : value;
    if(nmlst && nmlst.indexOf("Other") === 0 && nmlst.length > 1){
      nmlst = nmlst.filter(l => l !== "Other");
    }
    else if(nmlst && nmlst.indexOf("Other") > 0){
      nmlst = ["Other"];
    }
    var lst = [];
    mlsList.map((m) => {
      if(nmlst.indexOf(m.MLSName) >= 0){
        lst.push(m);
      }
      return null;
    });
    setValues((prevState) => ({
      ...prevState,
      MLSNames: nmlst,
      MLS: lst,
      NewMLSName: "",
      TopMsg: ""
    }));
    setSubmitStatus(0);
  };

  const handleCheck = (chk, param) => {
    // console.log("handleCheck");
    var pd = values.Products || [];
    if(chk && pd.indexOf(param) === -1){
      pd.push(param);
    }
    else if(!chk && pd.indexOf(param) >= 0){
      pd.splice(pd.indexOf(param), 1);
    }
    setValues((prevState) => ({
      ...prevState,
      Products: pd,
      TopMsg: ""
    }));
    setSubmitStatus(0);
  };

  const handleChange = (event) => {
    // console.log("handleChange");    
    const { target: { value, name } } = event;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
      TopMsg: ""
    }));
    setSubmitStatus(0);
  };

  const fetchMlsList = () => {
    var url = baseUrl + "idx/signuprequestmls";
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + nonExpToken,
      },
      method: "GET"
    })
    .then((response) => response.json())
    .then((json) => {
      // console.log("mlsList: ", json);
      if(json && json.length > 0){
        var lst = [];
        json.map(j => lst.push(j.MLSName));
        lst.push("Other");
        setMLSList(json.concat([{Id:0, MLSName: "Other"}]));
        setMLSNamesList(lst);
      }
    })
    .catch(function (data) {
      console.log("Error: ", data);
    });
  };

  const postData = (data) => {
    var url = baseUrl + "idx/signuprequest";
    setSubmitStatus(1);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + nonExpToken,
      },
      method: "POST",
      body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((json) => {
      // console.log("submit response: ", json);
      setSubmitStatus(2);
      setValues({...initValues, Products: []});
      scrollToElement(".top-msg", { offset: -10 });
    })
    .catch(function (data) {
      console.log("Error: ", data);
      setSubmitStatus(3);
      setValues({...initValues, Products: []});
      scrollToElement(".top-msg", { offset: -10 });
    });
  };

  const handleSubmit = () => {
    if(!values.FirstName || values.FirstName === ""){
      setValues((prevState) => ({
        ...prevState,
        TopMsg: "Please enter your First Name"
      }));
      setSubmitStatus(0);
      scrollToElement(".top-msg", { offset: -10 });
    }
    else if(!values.LastName || values.LastName === ""){
      setValues((prevState) => ({
        ...prevState,
        TopMsg: "Please enter your Last Name"
      }));
      setSubmitStatus(0);
      scrollToElement(".top-msg", { offset: -10 });
    }
    else if(!values.Email || values.Email === ""){
      setValues((prevState) => ({
        ...prevState,
        TopMsg: "Please enter your Email"
      }));
      setSubmitStatus(0);
      scrollToElement(".top-msg", { offset: -10 });
    }
    else if(!values.Phone || values.Phone === ""){
      setValues((prevState) => ({
        ...prevState,
        TopMsg: "Please enter your Phone"
      }));
      setSubmitStatus(0);
      scrollToElement(".top-msg", { offset: -10 });
    }
    else if(!values.MLS || values.MLS.length === 0){
      setValues((prevState) => ({
        ...prevState,
        TopMsg: "Please select MLS you are working with"
      }));
      setSubmitStatus(0);
      scrollToElement(".top-msg", { offset: -10 });
    }
    else if((values.MLS && values.MLS.length > 0 && values.MLSNames.indexOf("Other") >= 0) &&
      (!values.NewMLSName || values.NewMLSName === "")){
      setValues((prevState) => ({
        ...prevState,
        TopMsg: "Please enter MLS Name you are working with"
      }));
      setSubmitStatus(0);
      scrollToElement(".top-msg", { offset: -10 });
    }
    else if(!values.Products || values.Products.length === 0){
      setValues((prevState) => ({
        ...prevState,
        TopMsg: "Please select the Products you are interested in"
      }));
      setSubmitStatus(0);
      scrollToElement(".top-msg", { offset: -10 });
    }
    else {
      // console.log("All good");
      
      var bd = {};
      bd.FirstName = values.FirstName;
      bd.LastName = values.LastName;
      bd.Email = values.Email;
      bd.Phone = values.Phone;
      bd.Products = values.Products;
      if(values.MLS && values.MLS.length === 1 && values.MLS[0].Id === 0 && values.NewMLSName !== ""){
        bd.MLS = [{Id: 0, MLSName: values.NewMLSName}];
      }
      else {
        bd.MLS = values.MLS;
      }
      // console.log("bd: ", bd);
      postData(bd);
    }
  }

  React.useEffect(() => {
    fetchMlsList();
    
  }, []);
  
  // console.log("Render");
  // console.log("values: ", values);
  

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <img className='site-logo' src={TLCLogo} alt='TLCengine' />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <span className='site-logo-name'>TLC<span className='logo-sub-name'>engine</span></span>
          </Typography>
        </Toolbar>
      </AppBar>
      <div>
        <div className="top-header-section">
            <div className="section-header-bg">
                <div className="section-content-text">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="section-logo"></div>
                                <div className="section-box-typography">
                                    <br />
                                    <br />
                                    <br />
                                    <div className="samll-heading">Convert more web leads, close more transactions </div>
                                    <div className="main-heading">Housing Expenses (PITI) | Utilities | Transportation | Affordability Assessment</div>
                                    <div className="heading-text">A mortgage calculator that engages your leads on your existing IDX site</div>
                                    <br />
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div className="full-container-mid">
            <React.Fragment>
                <h2 >Tell us how can we help</h2>
                <CssBaseline />
                <Container maxWidth="sm">
                    <Grid className="from-mid top-msg" container spacing={2}>
                        {(submitStatus === 2) ?
                        <Grid item xs={12}>
                          <Alert severity="success">{"Thank you for showing interest in our Products. We will get in touch with your soon!"}</Alert>
                        </Grid> :
                        ((submitStatus === 3) ?
                        <Grid item xs={12}>
                          <Alert severity="error">{"Submitting your details failed, please try again later!"}</Alert>
                        </Grid> : null)
                        }
                        {(values.TopMsg && values.TopMsg !== "") &&
                        <Grid item xs={12}>
                          <Alert severity="error">{values.TopMsg}</Alert>
                        </Grid>
                        }
                        <Grid item xs={6}>
                            <Item className="remove-shadow">
                                <div className="input-group">
                                    <span className="field-label">Your First Name</span>
                                    <TextField 
                                      className="contactus-field-input" 
                                      variant="standard"
                                      name="FirstName"
                                      value={values.FirstName}
                                      onChange={handleChange} />
                                </div>
                            </Item>
                        </Grid>
                        <Grid item xs={6}>
                            <Item className="remove-shadow">
                                <div className="input-group">
                                    <span className="field-label">Your Last Name</span>
                                    <TextField 
                                      className="contactus-field-input" 
                                      variant="standard"
                                      name="LastName"
                                      maxLength="50"
                                      value={values.LastName}
                                      onChange={handleChange} />
                                </div>
                            </Item>
                        </Grid>
                        <Grid item xs={6}>
                            <Item className="remove-shadow">
                                <div className="input-group">
                                    <span className="field-label">Your Email</span>
                                    <TextField 
                                      className="contactus-field-input" 
                                      variant="standard"
                                      name="Email"
                                      maxLength="50"
                                      value={values.Email}
                                      onChange={handleChange} />
                                </div>
                            </Item>
                        </Grid>
                        <Grid item xs={6}>
                            <Item className="remove-shadow">
                                <div className="input-group">
                                    <span className="field-label">Your Phone</span>
                                    <TextField 
                                      className="contactus-field-input" 
                                      variant="standard"
                                      type="number"
                                      maxLength="10"
                                      name="Phone"
                                      value={values.Phone}
                                      onChange={handleChange} />
                                </div>
                            </Item>
                        </Grid>
                        <Grid item xs={12}>
                            <Item className="remove-shadow">
                                <div className="input-group">
                                    <span className="field-label">Select MLS your are working with</span>
                                    <ThemeProvider theme={theme}>
                                        <FormControl className="multiple-select-control">
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                name="MLS"
                                                multiple
                                                value={values.MLSNames}
                                                onChange={handleMLSChange}
                                                renderValue={(selected) => { return selected.join(', ');}}
                                                MenuProps={MenuProps}
                                                className="multiple-select-field"
                                                >
                                                {mlsNamesList.map((m, i) => (
                                                    <MenuItem key={i} value={m}>
                                                      <Checkbox checked={values.MLSNames.indexOf(m) > -1} />
                                                      <ListItemText primary={m} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </ThemeProvider>
                                </div>
                            </Item>
                        </Grid>
                        {(values.MLSNames.indexOf("Other") >= 0) &&
                        <Grid item xs={12}>
                            <Item className="remove-shadow">
                                <div className="input-group">
                                    <span className="field-label">Enter MLS Name your are working with</span>
                                    <TextField 
                                      className="contactus-field-input" 
                                      variant="standard"
                                      name="NewMLSName"
                                      maxLength="50"
                                      value={values.NewMLSName}
                                      onChange={handleChange} />
                                </div>
                            </Item>
                        </Grid>
                        }
                        <Grid item xs={12}>                            
                            <div className="heading-box">
                                <span className="heading-label">What Products you are interested in?</span>
                                <div className="heading-content">
                                  <Grid item xs={12}>
                                    <div><br/></div>
                                  </Grid>
                                  <Grid container spacing={2} className='bottom-msg' style={{padding: "12px 24px"}}>                                  
                                    <Grid item xs={12}>
                                      <FormGroup>
                                        <FormControlLabel control={
                                          <Checkbox 
                                            color="secondary"
                                            checked={values.Products.indexOf("Website") === -1 ? false : true}
                                            onChange={(e) => handleCheck(e.target.checked, "Website")} />
                                        } label="Website" />
                                        <FormControlLabel control={
                                          <Checkbox 
                                            color="secondary"
                                            checked={values.Products.indexOf("MobileApp") === -1 ? false : true}
                                            onChange={(e) => handleCheck(e.target.checked, "MobileApp")} />
                                        } label="Mobile App" />
                                      </FormGroup>
                                    </Grid>
                                  </Grid>
                                  <div>
                                  
                                  </div>
                                </div>
                            </div>                         
                        </Grid>                        
                        <Grid item xs={12}>
                            <Item className="remove-shadow" style={{marginTop: 50, textAlign: "center"}}>
                                <ThemeProvider theme={theme}>
                                  {(submitStatus === 1) ?
                                    <Button variant="contained" color="primary" style={{minWidth: 150}}>Submiting...</Button> :
                                    <Button variant="contained" color="primary" onClick={handleSubmit} style={{minWidth: 150}}>Submit</Button>
                                  }
                                </ThemeProvider>
                            </Item>
                        </Grid>
                    </Grid>
                </Container>
            </React.Fragment>
        </div>
        <div className="footer-content">
            <div className="footer-box-1">
                <span className="email-text"><a href="mailto:itsupport@tlcengine.com">itsupport@tlcengine.com</a></span>
                <span className="copy-rights">©2023 TLCengine. All Rights Reserved.</span>
            </div>   
            <div className="footer-box-2">
            <ul className="footer-soc-icon">
                <li><a href="https://www.facebook.com/TLCengine/" target="_blank" rel="noreferrer"> <FacebookIcon sx={{ fontSize:30 }}/> </a></li>
                <li><a href="https://twitter.com/TLCengine/" target="_blank" rel="noreferrer">  <TwitterIcon sx={{ fontSize:30 }}/> </a></li>
                <li><a href="https://www.linkedin.com/company/tlcengine/" target="_blank" rel="noreferrer"> <LinkedInIcon sx={{ fontSize:30 }}/></a></li>
            </ul>
            </div>
            <div className="footer-box-3">
                <div className="footer-logo"><a href="https://www.tlcengine.com/" target="_blank" rel="noreferrer"><img className='ft-logo' src={TLCFooterLogo} alt='TLCengine' /></a></div>
            </div>                                            
        </div>
    </div>
    </div>
  );
}

export default App;
